defineDs('Common/Framework/Foundation.SharedScripts/Scripts/Libraries/WheelSlider',
  [
    'Common/Framework/Foundation.SharedScripts/Scripts/Utils/SliderUtils'
  ], function (SliderUtils) {
    const defaultSettings = {
      containerSelector: '.slider-container',
      controls: {
        enabled: true,
        next: '.next',
        previous: '.previous',
        disabledClasses: []
      },
      elementClasses: {
        active: [],
        passive: [],
        general: []
      },
      settings: {
        enableMouseDrag: false,
        enableMobileDrag: true,
        diffAngle: 19,
        rotationMultiplier: 1
      }
    };

    return class WheelSlider {
      _container = null;
      _containerWrapper = null;
      _slides = null;
      _containerRect = null;
      _centerX = 0;
      _centerY = 0;
      _offsetValue = 0;
      _currentIndex = 0;
      _velocityMultiplier = 0.99;
      _minVelocityToStart = 25;
      _maxAllowedVelocity = 50;
      _stopVelocity = 1;
      _totalAngleShift = 0;
      _lastAngleShift = 0;
      _lastXs = [];
      _lastTimes = [];
      _mouseDownPosition = 0;
      _mouseUpPosition = 0;
      _lastClickTimestamp = null;
      _isDragging = false;
      _isMouseDown = false;
      _isStopped = false;
      _targetAtRelease = null;
      _velocity = 0;
      _velocityMax = 0;
      _slidesOutOfView = [];
      _slideRects = [];
      // _resizeObserver = null;
      _onTotalAngleShiftChange = () => {
      };
      _onSlideCallback = () => {
      };
      _onFrame = () => {
      };

      constructor(userSettings) {
        this._containerSelector = userSettings.containerSelector || defaultSettings.containerSelector;
        this._settings = { ...defaultSettings.settings, ...userSettings.settings };
        this._controls = { ...defaultSettings.controls, ...userSettings.controls };
        this._elementClasses = { ...defaultSettings.elementClasses, ...userSettings.elementClasses };

        this._eventListeners = [];
        this._init();
      }

      set onTotalAngleShiftChange(func) {
        if (typeof func !== 'function') throw new Error('Total angle shift change must be a function');
        this._onTotalAngleShiftChange = func;
      }

      set onNextClick(func) {
        if (typeof func !== 'function') throw new Error('Next click must be a function');
        this._onNextClick = func;
      }

      set onPreviousClick(func) {
        if (typeof func !== 'function') throw new Error('Previous click must be a function');
        this._onPreviousClick = func;
      }

      set onDragEnd(func) {
        if (typeof func !== 'function') throw new Error('Drag end must be a function');
        this._onDragEnd = func;
      }

      set onFrame(func) {
        if (typeof func !== 'function') throw new Error('Frame must be a function');
        this._onFrame = func;
      }

      set onSlideCallback(callback) {
        this._onSlideCallback = callback;
      }

      // make a function to update the settings
      updateSettings = (settings) => {
        this._settings = { ...this._settings, ...settings };
        this._moveSlides();
      };

      init = () => {
        this._removeEventListeners();
        this._init();
      };

      _callOnFrame = () => {
        this._onFrame();
      };

      _callDragEnd = () => {
        this._totalAngleShift = this._lastAngleShift;
        if (this._onDragEnd) this._onDragEnd();
      };

      _callNextClick = () => {
        if (this._onNextClick) this._onNextClick();
      };

      _callOnSlide = () => {
        this._onSlideCallback(this._slides);
      };

      updateTotalAngleShift = (angleChange) => {
        this._totalAngleShift += angleChange;
      };

      _callPreviousClick = () => {
        if (this._onPreviousClick) this._onPreviousClick();
      };

      _callTotalAngleShiftChange = (angle) => {
        this._onTotalAngleShiftChange(angle);
        this._lastAngleShift = angle;
      };

      async _setDimensions() {
        this._containerRect = await this._container.getBoundingClientRect();
        this._containerWrapperRect = await this._containerWrapper.getBoundingClientRect();
        this._centerX = this._containerRect.width / 2;
      }

      async _waitForDomUpdate() {
        // Wait for the next animation frame
        await new Promise((resolve) => requestAnimationFrame(resolve));
        // Continue with your code after the DOM has been updated
      }

      _removeEventListeners = () => {
        for (const { element, type, handler } of this._eventListeners) {
          element.removeEventListener(type, handler);
        }
        this._eventListeners = [];
        // this._resizeObserver.disconnect();
      };

      async _init() {
        if (typeof this._containerSelector === 'string') {
          this._container = document.querySelector(this._containerSelector);
        } else if (this._containerSelector instanceof HTMLElement) {
          this._container = this._containerSelector;
        }

        this._containerWrapper = this._container.parentElement;

        if (this._controls.enabled && this._controls.next && this._controls.previous) {
          // Next button
          if (typeof this._controls.next === 'string') {
            this._nextButton = document.querySelector(this._controls.next);
          } else if (this._controls.next instanceof HTMLElement) {
            this._nextButton = this._controls.next;
          }

          // previous button
          if (typeof this._controls.previous === 'string') {
            this._previousButton = document.querySelector(this._controls.previous);
          } else if (this._controls.previous instanceof HTMLElement) {
            this._previousButton = this._controls.previous;
          }
        } else {
          this._controls.enabled = false;
        }

        if (!this._container) {
          throw new Error('No container found');
        }

        this._slides = [...this._container.querySelectorAll(':scope > *')];

        if (!this._slides.length) {
          throw new Error('No elements found');
        }

        this._slides.forEach((slide, i) => {
          slide.dataset.index = i;
          slide.id = '_' + i;
          slide.classList.add(...this._elementClasses.general);
          if (slide.dataset.active) {
            this._currentIndex = -i;
          }
        });

        this._addEventListeners();

        await this._waitForDomUpdate();

        await this._setDimensions();

        await this._updateBoundingClientRects();

        // TODO: Fix this mess
        await this._moveOutOfView();
        await this._moveOutOfView();
        await this._moveOutOfView();

        this._moveSlides();

        await this._waitForDomUpdate();
        this._activateTransitions();
      }

      _addEventListener = (element, type, handler) => {
        element.addEventListener(type, handler);
        this._eventListeners.push({ element, type, handler });
      };

      _addEventListeners = () => {
        if (this._settings.enableMobileDrag) {
          this._addEventListener(this._container, 'touchstart', (event) => this._handleMouseDown(event));
          this._addEventListener(this._container, 'touchend', (event) => this._handleMouseUp(event));
          this._addEventListener(this._container, 'touchmove', (event) => this._handleMouseMove(event));
        }

        if (this._settings.enableMouseDrag) {
          this._addEventListener(this._container, 'mousedown', (event) => this._handleMouseDown(event));
          this._addEventListener(this._containerWrapper, 'mouseup', (event) => this._handleMouseUp(event));
          this._addEventListener(this._containerWrapper, 'mousemove', (event) => this._handleMouseMove(event));
        }

        if (this._controls.enabled) {
          this._addEventListener(this._nextButton, 'click', () => this._handleControlClick('next'));
          this._addEventListener(this._previousButton, 'click', () => this._handleControlClick('previous'));
        }

        this._slides.forEach((slide) => {
          this._addEventListener(slide, 'click', () => this._handleClick(slide));
        });

        this._addEventListener(window, 'resize', async () => {
          await this._setDimensions();
          await this._updateBoundingClientRects();
          await this._moveOutOfView();
          this._moveSlides();
        });

        // this._resizeObserver.observe(this._container.parentElement);
      };

      async _handleClick(slide) {
        const index = Number(slide.dataset.index);

        if (this._isDragging || index === this._findClosest().index) return;
        if (slide.classList.contains('active')) return;

        const activeSlide = this._containerWrapper.querySelector('.active');

        const direction = index - Number(activeSlide.dataset.index);
        if (direction) await this._handleControlClick(direction > 0 ? 'next' : 'previous');
      }

      async _handleMouseDown(e) {
        await this._updateBoundingClientRects();
        if (e.type === 'touchstart') this._lastXs.push(e.touches[0].clientX);
        this._isMouseDown = true;
        this._isStopped = false;
        this._velocity = 0;
        this._velocityMax = Number.MAX_SAFE_INTEGER;
        this._mouseDownPosition = this._lastXs.at(-1);
      }

      async _handleMouseUp() {
        await this._updateBoundingClientRects();
        if (!this._isMouseDown) return;

        this._mouseUpPosition = this._lastXs.at(-1);

        this._isMouseDown = false;
      }

      async _handleMouseMove(e) {
        await this._updateBoundingClientRects();
        if (!this._isDragging && this._isMouseDown) {
          this._isDragging = true;
          this._disableTransitions();
          requestAnimationFrame(() => this._slideMove());
        }

        this._lastXs.push(e.clientX || e.touches[0].clientX);
        this._lastTimes.push(performance.now());
      }

      async _handleControlClick(direction) {
        await this._updateBoundingClientRects();
        // throttle to prevent animation error
        if (this._lastClickTimestamp && Date.now() - this._lastClickTimestamp < 500) return;

        this._lastClickTimestamp = Date.now();
        this._currentIndex = this._currentIndex + (direction === 'next' ? -1 : 1);
        await this._moveOutOfView();
        if (direction === 'next') {
          this._callNextClick();
        } else {
          this._callPreviousClick();
        }
        this._moveSlides();
      }

      _findClosest = () => {
        let closestSlide;
        let shortestDistance = Number.MAX_SAFE_INTEGER;
        let shortestAbsoluteDistance = Number.MAX_SAFE_INTEGER;

        for (let [i, slide] of this._slides.entries()) {
          slide.dataset.active = 'false';
          const rect = this._slideRects[i];

          // The closest can only be a visible slide, so don't bother checking those out of view.
          if (rect.top > this._containerWrapperRect.bottom) {
            continue;
          }

          const distToCenter = (rect.left + (rect.width / 2)) - (this._centerX + this._containerRect.left);
          const absoluteDistToCenter = Math.abs(distToCenter);

          if (absoluteDistToCenter < shortestAbsoluteDistance) {
            shortestAbsoluteDistance = absoluteDistToCenter;
            shortestDistance = distToCenter;
            closestSlide = slide;
          }
        }

        return { index: closestSlide?.dataset?.index, distance: shortestDistance };
      };

      _activateTransitions = () => {
        const wrapper = document.querySelectorAll('.js-dlo-wheel-wrapper, .js-dli-wheel-wrapper')[0];
        wrapper?.style.setProperty('--wheel-bg-transition', '300ms');

        const container = document.querySelectorAll('.js-wheel-container, .js-dli-wheel-container')[0];
        container?.style.setProperty('--wheel-tile-transition', '300ms');
      };

      _moveSlides = () => {
        this._slides.forEach((slide) => {
          let offset = 0;
          const i = Number(slide.dataset.index);

          if (-i > this._currentIndex) offset = -this._offsetValue;
          else if (-i < this._currentIndex) offset = this._offsetValue;

          const angle = this._settings.diffAngle * (i + this._currentIndex);
          this._calcAndMove(angle, offset, slide);
        });

        // Move the slides on either side of the active slide to the top on DLI WHEEL
        const currentSlide = this._containerWrapper.querySelector('.js-dli-wheel-container .active');
        if (currentSlide) {
          const currentId = currentSlide.id;
          // remove _ from id
          const cardId = Number(currentId.substring(1));
          let prevId = cardId - 1;
          let nextId = cardId + 1;

          // if the current slide is the first slide, set the previous slide to the last slide
          if (cardId === 0) {
            prevId = this._slides.length - 1;
          }

          // if the current slide is the last slide, set the next slide to the first slide
          if (cardId === this._slides.length - 1) {
            nextId = 0;
          }

          const prevSlide = this._containerWrapper.querySelector('.js-dli-wheel-container div[id="_' + prevId + '"]');
          const nextSlide = this._containerWrapper.querySelector('.js-dli-wheel-container div[id="_' + nextId + '"]');
          prevSlide.style.top = '0px';
          nextSlide.style.top = '0px';
        }

        this._callOnSlide();
      };

      _calcAndMove = (angle, offset, slide) => {
        const coords = SliderUtils.getCoordsFromAngle(this._containerRect, angle - 90 + offset);
        const percentage = 100 - SliderUtils.scaleValue(Math.abs(angle % 365), [0, this._settings.diffAngle], [0, 100]);
        const heroXPosition = (coords.x + this._containerRect.left);
        const rotation = angle * this._settings.rotationMultiplier;

        if (coords.y === 0) {
          slide.classList.add('active');

          // remove onclick attribute from anchor link in active slide
          const anchor = slide.querySelector('a');
          if (anchor) {
            anchor.removeAttribute('onclick');
          }
        } else {
          slide.classList.remove('active');

          // add onclick attribute to anchor link in non-active slides
          const anchor = slide.querySelector('a');
          if (anchor) {
            anchor.setAttribute('onclick', 'event.preventDefault();');
          }
        }

        slide.dataset.angle = rotation.toString();
        slide.style.rotate = `${rotation}deg`;
        slide.style.setProperty('--percentage', percentage);
        slide.style.left = `${coords.x}px`;
        slide.style.top = `${coords.y}px`;

        // DLO WHEEL set the hero bg image properties
        const dloHero = document.querySelector(`.js-dlo-wheel-hero[data-card-id="${slide.dataset.cardId}"]`);
        if (dloHero) {
          dloHero.style.setProperty('--percentage', percentage);
          dloHero.style.setProperty('--x', heroXPosition + 'px');
          dloHero.style.setProperty('--distToCenter', (heroXPosition - (this._containerWrapperRect.width / 2)));

          if (slide.classList.contains('active')) {
            dloHero.classList.remove('invisible');
          } else {
            dloHero.classList.add('invisible');
          }
        }

        // DLI WHEEL set the hero bg image opacity
        const dliHero = this._containerWrapper.querySelector(`.js-dli-wheel-bg[data-card-id="${slide.dataset.cardId}"]`);
        if (dliHero) {
          if (slide.classList.contains('active')) {
            dliHero.classList.remove('opacity-0');
          } else {
            dliHero.classList.add('opacity-0');
          }
        }
      };

      async _moveOutOfView(source = 'button') {
        // console.log('Starting _moveOutOfView');
        this._slidesOutOfView = [];
        for (const [i, slide] of this._slides.entries()) {
          const divRect = this._slideRects[i];
          if (divRect.top > this._containerWrapperRect.bottom) {
            this._slidesOutOfView.push(slide);
          }
        }

        let active = this._containerWrapper.querySelector('.active');
        // console.log('Active slide:', active);

        let highIndex = Number.MIN_SAFE_INTEGER;
        let lowIndex = Number.MAX_SAFE_INTEGER;

        if (!active) {
          active = this._slides[0];
          active.classList.add('active');
        }

        const outOfViewCount = this._slidesOutOfView.reduce((accumulator, currentValue) => {
          const currentIndex = Number(currentValue.dataset.index);
          if (currentIndex > Number(active.dataset.index)) {
            accumulator.right++;
          } else {
            accumulator.left++;
          }

          return accumulator;
        }, { left: 0, right: 0 });

        // console.log('Out of view count:', outOfViewCount);

        for (const slide of this._slides) {
          const currentIndex = Number(slide.dataset.index);
          if (currentIndex > highIndex) highIndex = currentIndex;
          if (currentIndex < lowIndex) lowIndex = currentIndex;
        }

        const transitionDuration = source === 'button' ? '1ms' : '0';
        let slideToMove;
        if (outOfViewCount.left < 2) {
          slideToMove = [...this._slides].find((slide) => {
            return Number(slide.dataset.index) === highIndex;
          });
          slideToMove.style.transitionDuration = transitionDuration;
          slideToMove.dataset.index = (lowIndex - 1).toString();
        } else if (outOfViewCount.right < 2) {
          slideToMove = [...this._slides].find((div) => Number(div.dataset.index) === lowIndex);
          slideToMove.style.transitionDuration = transitionDuration;
          slideToMove.dataset.index = (highIndex + 1).toString();
        }

        await this._waitForDomUpdate();

        if (slideToMove) {
          const resetOnTransitionEnd = (event) => {
            event.target.style.transitionDuration = '';
            slideToMove.removeEventListener('transitionend', resetOnTransitionEnd);
          };
          slideToMove.addEventListener('transitionend', resetOnTransitionEnd);
        }

        // console.log('Finished _moveOutOfView');
      }

      _disableTransitions = () => {
        this._slides.forEach((slide) => {
          slide.style.transitionDuration = '0s';
          const hero = document.querySelector(`.js-dlo-wheel-hero[data-card-id="${slide.dataset.cardId}"]`);
          if (hero) hero.style.transitionDuration = '0s';
        });

        this._containerWrapper.querySelectorAll('.js-dots-wrapper > div').forEach((dot) => {
          dot.style.transitionDuration = '0s';
        });
      };

      _enableTransitions = () => {
        this._slides.forEach((slide) => {
          slide.style.transitionDuration = '';
          const hero = document.querySelector(`.js-dlo-wheel-hero[data-card-id="${slide.dataset.cardId}"]`);
          if (hero) hero.style.transitionDuration = '';
        });

        this._containerWrapper.querySelectorAll('.js-dots-wrapper > div').forEach((dot) => {
          dot.style.transitionDuration = '';
        });
      };

      async _slideMove() {
        const moved = this._mouseDownPosition - this._lastXs.at(-1);
        let target = -moved / 10;

        for (const slide of this._slides) {
          const i = Number(slide.dataset.index);
          let offset = 0;

          if (-i > this._currentIndex) offset = -this._offsetValue;
          else if (-i < this._currentIndex) offset = this._offsetValue;

          if (!this._isMouseDown && !this._isStopped) {

            this._isStopped = true;
            this._targetAtRelease = Math.floor(-moved / 10);
            const distance = Math.sqrt(Math.pow(this._lastXs.at(-1) - this._lastXs.at(-2), 2));
            this._directionAtRelease = this._lastXs.at(-1) > this._lastXs.at(-2) ? -1 : 1;
            const timeDiff = (this._lastTimes.at(-1) - this._lastTimes.at(-2)) / 10;

            this._velocity = Math.max(Math.min(this._maxAllowedVelocity, (distance / timeDiff) * this._directionAtRelease), -this._maxAllowedVelocity);
            this._velocityMax = this._velocity;

          } else if (!this._isMouseDown && this._isStopped) {
            this._velocity *= this._velocityMultiplier;
            target = this._velocity - this._velocityMax + this._targetAtRelease;
          }

          if (this._isStopped && (Math.abs(this._velocity) < this._stopVelocity || Math.abs(this._velocityMax) < this._minVelocityToStart)) {
            this._totalAngleShift += this._lastAngleShift;
            this._enableTransitions();
            this._isDragging = false;
            const closestDiv = this._findClosest().index;

            this._currentIndex = -Number(closestDiv);
            this._moveSlides();
            this._callDragEnd();
            return;
          }

          const angle = (this._settings.diffAngle * (i + this._currentIndex)) + target + offset;

          this._calcAndMove(angle, offset, slide);
        }
        this._callTotalAngleShiftChange(this._totalAngleShift + target);
        await this._moveOutOfView('drag');


        requestAnimationFrame(() => this._slideMove());
      }

      async _updateBoundingClientRects() {
        this._slideRects = [];

        for (const slide of this._slides) {
          this._slideRects.push(await slide.getBoundingClientRect());
        }

        this._callOnFrame();
      }
    };
  });
